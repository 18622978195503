import { useContext, useEffect, useState } from 'react';

interface Props {
  width?: number;
}

const Spin = ({ width = 80 }: Props) => {
  return (
    <div className="spinner-container">
      <div className="spinner" style={{ width, height: width }}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
export default Spin;