import React, { createContext, useContext, useState } from 'react';
import './LoanList.scss';
import DropdownBlue from '../../../components/Dropdowns/Dropdown/Dropdown';
import LoanDetails from './LoanDetails/LoanDetails';
import { ILoanListItemProps, LoanListItem, LoanStatus } from './components/LoanItemList';
import { useGetLoans } from '../../../services/loan/querys';
import { LoanDetailContext } from '../Private';
import { TaskState } from '../../../gql/graphql';
import Spin from '../../../components/Atoms/Loader/Spin';

function countLoansByStatus(loans: any[]) {
  const counts = {
    all: 0,
    review: 0,
    approval: 0,
  };

  for (const loan of loans) {
    counts.all++;

    const isAllApproved = loan.tasks.length > 0 && loan.tasks.every((item: any) => item.state === TaskState.Approved);;
    const isReview = loan?.tasks?.find((item: any) => item.state === TaskState.Review);

    if (isReview) counts.review++;
    if (isAllApproved) counts.approval++;
  }

  return counts;
}

function filterLoansByBorrowerOrAddress(loans: ILoanListItemProps[], searchTerm: string) {
  return loans.filter(loan =>
    loan.owner.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    loan.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
}

function FilterLoansByBorrowerOrAddress(searchTerm: string) {
  return () => (loans: ILoanListItemProps[]) => {
    return filterLoansByBorrowerOrAddress(loans, searchTerm);
  }
}

/* Loan List */
const SearchIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
    <rect width="30" height="30" rx="6" transform="matrix(0 -1 -1 0 30 30)" fill="#F9FAFB" />
    <path d="M14.1667 20.8333C17.8486 20.8333 20.8333 17.8486 20.8333 14.1667C20.8333 10.4848 17.8486 7.5 14.1667 7.5C10.4848 7.5 7.5 10.4848 7.5 14.1667C7.5 17.8486 10.4848 20.8333 14.1667 20.8333Z" stroke="#929FB0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M22.5005 22.5003L18.8755 18.8753" stroke="#929FB0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
)

export const OpenTaskContext = createContext<{ taskKey: string; setTaskKey: any } | {}>({});

interface ILoanListProps {
  children?: React.ReactNode;
}

const LoanList: React.FC<ILoanListProps> = ({ }) => {
  const { loan: loanSelected, setLoan: setLoanSelected }: any = useContext(LoanDetailContext);

  /* Badges */
  const [selectedStatus, setSelectedStatus] = useState<LoanStatus | null>(null);

  const { data: loans, fetching: loansLoading } = useGetLoans()

  const counts = countLoansByStatus(loans as any || []);

  const badgesMarkup = (
    <React.Fragment>
      <StatusBadge
        title="All"
        count={counts.all}
        isSelected={!selectedStatus}
        onClick={() => setSelectedStatus(null)}
      />
      <StatusBadge
        title="Needs Review"
        count={counts.review}
        isSelected={selectedStatus === 'review'}
        onClick={() => setSelectedStatus('review')}
      />
      <StatusBadge
        title="Final Approval"
        count={counts.approval}
        isSelected={selectedStatus === 'approval'}
        onClick={() => setSelectedStatus('approval')}
      />
    </React.Fragment>
  )

  const [filterFunction, setFilterFunction] = useState<any>();
  const [openTask, setOpenTask] = useState<string>();

  const onSearchHandler = (term: string) => setFilterFunction(FilterLoansByBorrowerOrAddress(term))

  const onClickItemLoan = (loan: any) => {
    setLoanSelected(loan);
  }

  let filteredLoans: any = loans;


  if (selectedStatus) {
    filteredLoans = loans?.filter((loan: any) => {
      if (selectedStatus === 'approval') {
        return loan.tasks.length > 0 && loan.tasks.every((item: any) => item.state === TaskState.Approved);;
      } else if (selectedStatus === 'review')
        return !!loan.tasks.find((item: any) => item.state === TaskState.Review);
    });
  }

  if (filterFunction)
    filteredLoans = filterFunction(filteredLoans);

  filteredLoans = filteredLoans?.sort((a: any, b: any) =>
    new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );

  return (
    <OpenTaskContext.Provider value={{ taskKey: openTask, setTaskKey: setOpenTask }}>
      <div className="loan-list">
        <div className='top-container'>
          <div className='title'>
            <DropdownBlue items={[{ key: 'Active', label: "Active" }]} label={"Active"} onClickSelect={() => { }} /> loans
          </div>
          <div>
            <SearchBox onSearch={onSearchHandler} />
          </div>
        </div>
        <div className='badges-container'>
          {badgesMarkup}
        </div>
        <div className='loans-container'>
          {loansLoading && <Spin />}
          { !loansLoading &&
            filteredLoans?.map((loan: any, index: any) => (
              <LoanListItem key={index} {...loan} onClick={() => onClickItemLoan(loan)} />
            ))
          }
        </div>
      </div>
      {
        loanSelected ?
          <div className='floating-window'>
            <div className='floating-overlay' onClick={() => setLoanSelected(undefined)}></div>
            <div className='floating-content'>
              <LoanDetails onClose={() => setLoanSelected(undefined)} />
            </div>
          </div>
          :
          null
      }
    </OpenTaskContext.Provider>
  );
};

/* Search Box */
interface ISearchBoxProps {
  onSearch: (searchTerm: string) => void;
}

const SearchBox: React.FC<ISearchBoxProps> = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    onSearch(event.target.value);
  };

  return (
    <div className='search-box'>
      <SearchIcon />
      <input
        type="text"
        placeholder="Filter by borrower or loan address"
        value={searchTerm}
        onChange={handleSearch}
      />
    </div>
  )
}

/* Status Badge */
interface IStatusBadgeProps {
  title: string;
  count?: number;
  isSelected?: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
}

export const StatusBadge: React.FC<IStatusBadgeProps> = (props) => {
  const { title, count, isSelected, onClick } = props;

  const className = isSelected ? 'status-badge selected' : 'status-badge';

  return (
    <div className={className} onClick={onClick}>
      <span className='title'>{title}</span>
      {count !== undefined ?
        <React.Fragment>
          {' '}
          <span className='count'>{count}</span>
        </React.Fragment>
        :
        null
      }
    </div>
  )
}

/* Exports */
export default LoanList;